import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

//Componente de abogado que recibe como prop el idioma, y la informacion del abogado
//agregar que reciba idioma en el que tiene que estar la informacion

const Abogado = styled.div`
  background-color: var(--blanco);
  padding: 22px;
  width: 100%;
  color: var(--azul-100);
  font-size: var(--text-base-size);
  text-align: left;

  .imgContainer {
    margin-bottom: 45px;
    min-width: 291px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
  }

  .infoContainer button {
    border: none;
    background-color: unset;
    color: var(--amarillo);
    font-weight: bold;
    margin: auto auto 0;
    display: block;
    cursor: pointer;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 30px;
  }

  .mail {
    color: var(--azul200);
  }

  .mail:hover {
    color: var(--amarillo);
  }

  .celular {
    margin: 0;
  }

  .socialIconsContainer {
    margin-bottom: 45px;
  }

  .socialIconsContainer img {
    margin-right: 15px;
    margin-top: 15px;
  }

  .titulo {
    font-weight: bold;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 750px) {
    display: flex;

    .imgContainer {
      margin-right: 45px;
    }

    .imgContainer {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 1000px) {
    padding: 60px;

    .socialIconsContainer img {
      max-width: 25px;
    }
  }
`

const AbogadoComp = ({ abogado }) => {
  const [showingSecondaryInfo, setShowingSecondaryInfo] = useState(false)

  const toggleSecondaryText = () => {
    console.log("asdad")
    setShowingSecondaryInfo(!showingSecondaryInfo)
  }
  return (
    <Abogado>
      <div className="imgContainer">
        <Img fluid={abogado.foto} />
      </div>
      <div className="infoContainer">
        <h3>{abogado.nombre}</h3>
        <a
          className="mail"
          target="_blank"
          rel="noopener"
          href={"mailto:" + abogado.mail}
        >
          {abogado.mail}
        </a>
        <p className="celular">{abogado.telefono}</p>
        <div className="socialIconsContainer">{abogado.redes}</div>
        <p className="titulo">Formación</p>
        <p className="info">{abogado.formacion}</p>
        {showingSecondaryInfo && (
          <div className="secondary-info">
            {abogado.actividadesAcademicas && (
              <>
                <p className="titulo">Actividades Académicas</p>
                <p className="info">{abogado.actividadesAcademicas}</p>
              </>
            )}

            {abogado.actividadesAH && (
              <>
                <p className="titulo">
                  Actividades <em>Ad Honorem</em>
                </p>
                <p className="info">{abogado.actividadesAH}</p>
              </>
            )}
            {abogado.publicaciones && (
              <>
                <p className="titulo">Publicaciones</p>
                <p className="info">{abogado.publicaciones}</p>
              </>
            )}
            {abogado.actividadesAcademicas && (
              <>
                <p className="titulo">Congresos y Seminarios</p>
                <p className="info">{abogado.congresos}</p>
              </>
            )}
            {abogado.membresias && (
              <>
                <p className="titulo">Membresías</p>
                <p className="info">{abogado.membresias}</p>
              </>
            )}
            <p className="titulo">Idiomas</p>
            <p className="info">{abogado.idiomas}</p>
          </div>
        )}
        {!showingSecondaryInfo ? (
          <button onClick={toggleSecondaryText}>mostrar más +</button>
        ) : (
          <button onClick={toggleSecondaryText}>mostrar menos -</button>
        )}
      </div>
    </Abogado>
  )
}

export default AbogadoComp
