import React from "react"
import Layout from "../components/layout"
import Abogado from "../components/abogado"
import SEO from "../components/seo"
import styled from "styled-components"
import fbIcon from "../images/facebook-icon.svg"
import twitterIcon from "../images/twitter-icon.svg"
import linkedinIcon from "../images/linkedin-icon.svg"
import { graphql } from "gatsby"

const NosotrosContainer = styled.div`
  .containerTexto {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    padding: 0 15px;
    margin-bottom: 90px;
  }

  @media only screen and (min-width: 1000px) {
    .containerTexto {
      padding: 0;
    }

    .containerTexto p {
      margin-left: 120px;
    }
  }

  .containerTexto h1 {
    margin-bottom: 55px;
  }

  .abogadosContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 75px;
    margin: 0 auto;
  }
`

const Nosotros = ({ data }) => {
  const FTurbaux = {
    foto: data.fabriceTurbaux.childImageSharp.fluid,
    nombre: "Fabrice Turbaux",
    mail: "fturbaux@estudiotfz.com",
    telefono: "+595 981 406071",
    // redes: (
    //   <>
    //     <a href="facebook.com" target="_blank" rel="noopener  noreferrer">
    //       <img src={fbIcon} alt="facebook-icon" />
    //     </a>
    //     <a href="twitter.com" target="_blank" rel="noopener  noreferrer">
    //       <img src={twitterIcon} alt="twitter-icon" />
    //     </a>
    //     <a href="linkedin.com" target="_blank" rel="noopener  noreferrer">
    //       <img src={linkedinIcon} alt="linkedin-icon" />
    //     </a>
    //   </>
    // ),
    formacion:
      "Facultad de Derecho y Ciencias Sociales – Universidad Nacional de Asunción (Abogado, 1993); Universidad de Leyes de Arizona (Master en Derecho Comercial Internacional. Estados Unidos, 1998.",
    actividadesAcademicas:
      "Profesor de la Universidad Nacional de Asunción desde 1.995 hasta la actualidad. Desde 2005 Profesor encargado de catedra de Derecho Mercantil I de la Carrera de Notariado de la misma Universidad.",
    publicaciones:
      "Tesis de postgrado publicada en el Webpage del National Law Center for Inter-American Free Trade: www.natlaw.com; artículo publicado sobre comercio electrónico en la revista del National Law Center; participación en el libro “Derecho Bancario”, publicado por el Dr. Torres Kirmser, en los capítulos de Crédito Documentario, edición 1995, y Crédito Documentario y Letra de Crédito Standby o Garantía Independiente, edición 1999. Co-autor en el libro “Derecho Mercantil – parte general”, editorial La Ley Paraguaya, edición 2009, “Fondo de Comercio”.",
    congresos:
      "Disertante en la 1ra. Conferencia Interamericana de Normatividad Bancaria, 24 y 25 de Septiembre de 1.998, México, D.F. Organizado por el Centro Jurídico Nacional para el Libre Comercio Inter-Americano (NLCIFT); III Congreso de Magistrados del Mercosur “Armonización de las Legislaciones y cooperación judicial, propuestas concretas para su posible implementación”. Congreso de Derecho Bancario, Miami, Florida (EE.UU.); XVI Congreso Latinoamericano de Derecho Bancario, celebrado en Panamá, del 28 al 30 de mayo de 1.997, organizado por la Asociación Bancaria de Panamá y la Federación Latinoamericana de Bancos; XVII Congreso Latinoamericano de Derecho Bancario, celebrado en San Pedro Sula, Honduras, organizado por la FELABAN.",
    membresias:
      "Instituto Paraguayo de Derecho Bancario; Representante honorario de la UNCITRAL (Comisión de las Naciones Unidas para el Derecho Comercial Internacional).",
    idiomas:
      "Francés (Traductor Público Francés-Español, matriculado ante la Corte Suprema de Justicia); Español; Inglés; Portugués.",
  }

  const AFuchs = {
    foto: data.andreasFuchs.childImageSharp.fluid,
    nombre: "Andreas Fuchs",
    mail: "afuchs@estudiotfz.com",
    telefono: "+595 981 718268",
    redes: (
      <>
        <a
          href="https://www.facebook.com/fuxlex"
          target="_blank"
          rel="noopener  noreferrer"
        >
          <img src={fbIcon} alt="facebook-icon" />
        </a>
        <a
          href="https://twitter.com/aguarafux"
          target="_blank"
          rel="noopener  noreferrer"
        >
          <img src={twitterIcon} alt="twitter-icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/andreasfuchspy/"
          target="_blank"
          rel="noopener  noreferrer"
        >
          <img src={linkedinIcon} alt="linkedin-icon" />
        </a>
      </>
    ),
    formacion:
      "1982–1992 Colegio Goethe Schule Asunción. 1995-1998 Universidad Nacional Andrés Bello, Chile. 1998-2006 Universidad Nacional de Asunción, Paraguay. Carrera Derecho. Titulo: Abogado. Maestría en Derecho Procesal. Cohorte 13. Universidad Nacional de Rosario, Argentina (2010 -2011).",
    actividadesAcademicas:
      "Profesor Auxiliar de la cátedra de Derecho Mercantil, Notariado, de la Universidad Nacional de Asunción.",
    actividadesAH:
      "Miembro del Rotaract Club Trinidad. 1998-1999. Bombero Voluntario en el Cuerpo de Bomberos Voluntarios del Paraguay. Cuarta Compañía Luque. 1999-2014.",
    publicaciones:
      "“Experiencia de Inclusión Social con Jóvenes de Sectores Carenciados en las Mercociudades”, Mercociudades y GTZ, 2005. (Coordinación Técnica de la Edición)",
    congresos:
      "0/2002 GTZ, Cooperación Técnica Alemana. “Segundo Seminario de Asistentes de Proyectos – Gestión Administrativa y Financiera”. Asunción, Paraguay. 09/2003 GTZ, Cooperación Técnica Alemana. “Formación de Moderadores”. Concepción, Paraguay. 10/2003 CEDIAL, Centro de Cooperación Empresarial y Desarrollo Industrial. “Seminario Internacional – La calidad aplicada a la Gestión Pública”. Asunción, Paraguay. 09/2004 GTZ, Cooperación Técnica Alemana. “Taller de Visualización y Moderación de Grupos”. Lima, Perú. “Garantías Constitucionales“. Circulo de Abogadas del Paraguay. Asunción, Paraguay",
    idiomas: "Alemán, Español e Inglés.",
  }

  const EZorraquin = {
    foto: data.estebanZorraquin.childImageSharp.fluid,
    nombre: "Esteban Zorraquín",
    mail: "ezorraquin@estudiotfz.com",
    telefono: "+595 981 425554",
    // redes: (
    //   <>
    //     <img src={fbIcon} alt="facebook-icon" />
    //     <img src={twitterIcon} alt="twitter-icon" />
    //     <img src={linkedinIcon} alt="linkedin-icon" />
    //   </>
    // ),
    formacion:
      "1987–2000 Colegio San Patricio. 2001–2006. Universidad Nacional de Asunción, Facultad de Derecho; Asunción, Paraguay; Título: Abogado. Universidad Nacional de Rosario, Facultad de Derecho; Rosario, Argentina; Maestría en derecho privado. (2011-2012)",
    actividadesAcademicas:
      "Profesor Auxiliar de la cátedra de Derecho Mercantil, Notariado, de la Universidad Nacional de Asunción.",
    congresos:
      "«Análisis Lógico-Jurídico de Antecedentes Jurisprudenciales». Defensoria del Pueblo. Asunción, Paraguay. 2009. «Garantías Constitucionales«. Circulo de Abogadas del Paraguay. Asunción, Paraguay. 2009.«Conferencia Magistral «. Derecho Procesal. Instituto Panamericano de Derecho Procesal. Asunción, Paraguay. 2007. «Conferencia Magistral «. Derecho Procesal. Instituto Panamericano de Derecho Procesal. Asunción, Paraguay. 2007. «Conferencia Magistral». Derecho Procesal. Instituto Panamericano de Derecho Procesal. Asunción, Paraguay. .2008. «Juicio Oral y Delito de Incumplimiento del Deber Legal Alimentario». Universidad Nacional de Asunción. Asunción, Paraguay. 2003. «Panorama Actual de la Teoría del Delito». Colegio de Abogados del Paraguay. Asunción, Paraguay. 2003. «Finanzas Publicas y Derecho Tributario». Universidad Nacional de Asunción. Asunción, Paraguay. 2004. «Primeras Jornadas Paraguayas de derecho de la navegación por agua del siglo XXI». Universidad Nacional de Asunción. Asunción, Paraguay. 2002. «Sociedades Anonimas«.Circulo de Abogadas del Paraguay. Paraguay. 2009. «Cursos de Actualización de Derecho Procesal Civil». Universidad Nacional de Asunción.Asunción, Paraguay. 2010. «Cursos de Actualización de Derecho Administrativo«. Universidad Nacional de Asunción. Asunción, Paraguay. 2010. Énfasis en las Áreas: Litigios, Derecho Privado (Civil, Laboral), Derecho procesal, Derecho administrativo.",
    idiomas: "Español, Inglés, Portugués.",
  }

  const FZorraquin = {
    foto: data.facundoZorraquin.childImageSharp.fluid,
    nombre: "Facundo Zorraquín",
    mail: "fzorraquin@estudiotfz.com",
    telefono: "+595 981 521958",
    // redes: (
    //   <>
    //     <img src={fbIcon} alt="facebook-icon" />
    //     <img src={twitterIcon} alt="twitter-icon" />
    //     <img src={linkedinIcon} alt="linkedin-icon" />
    //   </>
    // ),
    formacion:
      "1999–2005 Colegio San Patricio. 2006–2012. Universidad Nacional de Asunción, Facultad de Derecho; Asunción, Paraguay; Título: Abogado. Universidad Nacional de Rosario, Facultad de Derecho; Rosario, Argentina; Maestría en derecho privado. (2013-2014)",
    idiomas: "Español e Inglés",
  }

  return (
    <Layout>
      <SEO title="Nosotros" />
      <NosotrosContainer>
        <div className="containerTexto">
          <h1>Quiénes Somos</h1>
          <p>
            Estudio jurídico fundado en el año 1994, por el abogado Fabrice
            Turbaux quien a lo largo de los años, ha consolidado un equipo
            profesional, litigando y ejerciendo el derecho principalmente en el
            área civil y comercial. Actualmente sus áreas de practica se han
            extendido a la asesoría comercial contractual, laboral y
            administrativa, sin perder de vista el origen y el sustento que dio
            vida como lo fue la litigación, base solida que sigue manteniendo
            hasta el día de hoy servicios legales actualizados y personalizados
            con la confianza, orden y seriedad que siempre nos ha caracterizado.
          </p>
        </div>
        <div className="abogadosContainer">
          <Abogado abogado={FTurbaux} />
          <Abogado abogado={AFuchs} />
          <Abogado abogado={EZorraquin} />
          <Abogado abogado={FZorraquin} />
        </div>
      </NosotrosContainer>
    </Layout>
  )
}

export default Nosotros

export const query = graphql`
  query NosotrosQuery {
    fabriceTurbaux: file(relativePath: { eq: "miembros/fabrice_turbaux.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    andreasFuchs: file(relativePath: { eq: "miembros/andreas_fuchs.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    estebanZorraquin: file(
      relativePath: { eq: "miembros/esteban_zorraquin.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    facundoZorraquin: file(
      relativePath: { eq: "miembros/facundo_zorraquin.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
